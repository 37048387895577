import React, { BaseSyntheticEvent, FunctionComponent, useEffect, useState } from "react"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import { SignInStep } from "../flows/SignIn"
import { SignUpStep } from "../flows/SignUp"
import { InvitationStep } from "../flows/Invitation"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { logout, setLoading } from "../store/actions"
import { asyncLogin } from "../store/thunks"
import { useIntl } from "react-intl"
import { useCommonStyles } from "../utils/styles"
import { StepMask } from "./StepMask"
import Typography from "@material-ui/core/Typography"
import { wrongPasswordErrorCode, tooManyLoginAttempts } from "../utils/errorCodes"

export enum PasswordStepType {
    otp = "otp",
    password = "password",
}

type PasswordStepProps = {
    onStepNavigation: (step: SignInStep | SignUpStep | InvitationStep, back?: boolean) => void
    type: PasswordStepType
}

export const PasswordStep: FunctionComponent<PasswordStepProps> = (props) => {
    const { register, handleSubmit, errors, reset, clearError } = useForm()
    const loading = useSelector((state: IStoreState) => state.loading)
    const user = useSelector((state: IStoreState) => state.user)
    const error = useSelector((state: IStoreState) => state.error)

    const [orgError, setOrgError] = useState(false)

    const dispatch = useDispatch()
    const { formatMessage: intl } = useIntl()

    const classes = useCommonStyles()

    const onSubmit = async (data: Record<string, any>, e?: BaseSyntheticEvent) => {
        e?.preventDefault()
        if (user?.email) {
            dispatch(asyncLogin(user?.email, data.password))
            reset()
            clearError(["password"])
        } else props.onStepNavigation(SignInStep.email, true)
    }

    const renderError = () => {
        if (!!errors.password) return (errors.password as any).message

        else if (error?.data?.error?.code === wrongPasswordErrorCode) return displayWrongPasswordError()
        else if (error?.data?.error?.code === tooManyLoginAttempts) return displayTooManyLoginAttemptsError()
        else if (orgError) return "You are not a part of any Organization. Contact your organization admin"
        else return " "
    }

    const displayWrongPasswordError = () => {

        const failedAttempts = error?.data?.failedAttempts;
        const attemptsLeft = error?.data?.remainingAttempts;

        if(failedAttempts < 2) {
            return intl({ id: "errors.api." + wrongPasswordErrorCode, defaultMessage: "Error" })
        }
        return intl({ id: "errors.api." + wrongPasswordErrorCode + '.attemptsLeft', defaultMessage: "Error" }, {attemptsLeft})

    }

    const displayTooManyLoginAttemptsError = () => {

        const blockedUntil = new Date(error?.data?.blockedUntil).getTime();
        const now = new Date().getTime()
    
        let remainingMinutes = Math.floor((Math.abs((blockedUntil - now)/1000))/60);
        if(remainingMinutes === 0){
            remainingMinutes = 1;
        }
        
        return intl({ id: "errors.api." + tooManyLoginAttempts, defaultMessage: "Error" }, {remainingMinutes})

    }

    const goToRecoverPwd = (e: any) => {
        e.preventDefault()
        props.onStepNavigation(SignInStep.recover)
        reset()
        clearError(["password"])
    }

    const goToSignIn = (e: any) => {
        e.preventDefault()
        setLoading(!loading)
        dispatch(logout())
        reset()
        clearError(["password"])
    }

    useEffect(() => {
        if (user?.logged && (user?.otp === "REQUESTED" || (user?.organisations && user?.organisations.length > 0))) {
            props.onStepNavigation(SignInStep.otp)
            reset()
            clearError(["password"])
        } else if (user?.logged && (user?.otp === "REQUESTED" || (user?.organisations && user?.organisations.length === 0))) {
            setOrgError(true)
            reset()
        } else if (!user?.email) {
            props.onStepNavigation(SignInStep.email)
            reset()
            clearError(["password"])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return (
        <StepMask title={ intl({ id: "steps.welcome" }) } subtitle={ user?.email }
                  secondaryAction={ intl({ id: "pwdStep.changeAcc" }) } onSecondaryAction={ goToSignIn }
                  submitText={ intl({ id: "steps.login" }) } handleSubmit={ handleSubmit(onSubmit) }>
            <TextField
                autoFocus
                variant="outlined"
                required
                fullWidth
                label={ intl({ id: "pwdStep.label" }) }
                name="password"
                inputRef={ register({ required: intl({ id: "errors.required" }) }) }
                type="password"
                autoComplete="current-password"
                error={ !!errors.password || !!error || orgError }
                helperText={ renderError() }
                style={{marginBottom: '15px'}}
            />
            <Box textAlign="left">
                <Typography className={ classes.buttonLink } onClick={ goToRecoverPwd }>
                    { intl({ id: "pwdStep.forgot" }) }
                </Typography>
            </Box>
        </ StepMask>
    )
}