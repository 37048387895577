import { Button, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { parsePhoneNumberFromString as parseMobile } from "libphonenumber-js/mobile"
import getPkce from "oauth-pkce"
import React, { BaseSyntheticEvent, FunctionComponent, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import AzureIcon from "../assets/ms_logo.svg"
import { SignInStep } from "../flows/SignIn"
import { SignUpStep } from "../flows/SignUp"
import { setLoading, setUser } from "../store/actions"
import { IStoreState, IUser, Provider } from "../store/types"
import { StepMask } from "./StepMask"


type EmailPhoneStepProps = {
    type: "signin" | "signup"
    onStepNavigation: (step: SignInStep | SignUpStep) => void
}

const useStyles = makeStyles(theme => ({
    iconRoot: {
        height: 48,
        width: 48,
        textAlign: "center",
    },
    imageIcon: {
        height: 48,
        width: 24,
    },
    providerButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        width: '100%',
        height: '100%',
        textTransform: 'none',
    },
  }))
  
  
export const EmailPhoneStep: FunctionComponent<EmailPhoneStepProps> = (props) => {
    const azureSubmitButton = useRef<HTMLButtonElement>(null);
    const { register, handleSubmit, errors } = useForm()
    const loading = useSelector((state: IStoreState) => state.loading)
    const service = useSelector((state: IStoreState) => state.service)
    const hasLocal = service.providers.includes(Provider.local)
    const dispatch = useDispatch()
    const { formatMessage: intl } = useIntl()
    let history = useHistory()
    const classes = useStyles()
    const [pkce, setPkce] = useState<{ verifier: any, challenge: any}>({
        verifier: "",
        challenge: ""
    })

    const azureAuthorizeUri = `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/v2.0/authorize`;
    const googleAuthorisationUri = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT}&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&state=${encodeURIComponent(`${service.redirectUri},${service.idOrganisation}`)}`;

    const redirectToGoogleAuth = () => {
        window.open(googleAuthorisationUri, '_self')
    }

    const onSubmit = async (data: IUser, e?: BaseSyntheticEvent) => {
        e?.preventDefault()
        dispatch(setUser(data))
        props.onStepNavigation(SignInStep.password)
    }

    useEffect(() => {
        if(service.providers.length === 1 && service.providers[0] === Provider.azure){
            getPkce(43, (error, { verifier, challenge }) => {
                setPkce({ verifier, challenge })
                localStorage.setItem('azure_code_challenge', challenge);
                if(challenge && azureSubmitButton && azureSubmitButton.current) {
                    azureSubmitButton.current.click()
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service.providers])


    return (
        <>
            { service.providers.includes(Provider.local) ?
                <StepMask
                    title={""}
                    subtitle={ props.type === "signin" ? intl({ id: "emailStep.signIn" })  + " - " + intl({ id: "emailStep.toGo" }, {serviceName: service.name === "Nova Auth" ? "Logifuture Auth" : service.name}) : intl({ id: "emailStep.signUp" }) + " - " + intl({ id: "emailStep.toGo" }, {serviceName: service.name === "Nova Auth" ? "Logifuture Auth" : service.name})}
                    secondaryAction={ props.type === "signin" ? undefined : intl({ id: "emailStep.already" }) }
                    onSecondaryAction={ () => {
                        setLoading(!loading)
                        props.type === "signin" ? history.push("/signup") : history.push("/login")
                    } } submitText={ intl({ id: "steps.next" }) } handleSubmit={ handleSubmit(onSubmit) }>
                    <TextField
                        autoFocus={ true }
                        variant="outlined"
                        required={ true }
                        fullWidth={ true }
                        id="email"
                        label={ intl({ id: "emailStep.label" }) }
                        name="email"
                        autoComplete="email"
                        inputRef={ register({
                            validate: {
                                required: (value: string) => ((value && value.length > 0)) || intl({ id: "errors.required" }),
                                phoneNumberOrEmail: (value: string) => {
                                    let regExpEmail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                    return regExpEmail.test(value) || parseMobile(value)?.isValid() || intl({ id: "errors.invalidEmailPhone" })
                                }
                            }
                        }) }
                        type="email"
                        error={ !!errors.email }
                        helperText={ (!!errors.email && (errors.email as any).message) || " " }
                    />
                    {
                        service.providers && 
                        service.providers.length > 1 && 
                        service.providers?.includes(Provider.google) ?
                       
                        
                        <Button classes={ { root: classes.providerButton } }onClick={redirectToGoogleAuth}>
                                Sign in with google
                        </Button>
                            
                         : <></>
            }
                </StepMask> : <></>
            }
            { service.providers.length === 1 && service.providers[0] === Provider.azure && pkce.challenge ?
                <form action={azureAuthorizeUri} method="post">
                    <input type="hidden" name='client_id' value={ process.env.REACT_APP_AZURE_CLIENT_ID }/>
                    <input type="hidden" name='response_type' value="code"/>
                    <input type="hidden" name='code_challenge' value={ pkce.challenge } />
                    <input type="hidden" name='redirect_uri' value={ process.env.REACT_APP_AZURE_REDIRECT }/>
                    <input type="hidden" name='state' value={ encodeURIComponent(
                        `${service.redirectUri},${service.idOrganisation}`
                    ) }/>
                    <input type="hidden" name='scope' value="openid profile"/>
                    <IconButton style={{ display: "none"}} type="submit" ref={azureSubmitButton}>
                        <img width={ hasLocal ? "28px" : "44px" } 
                            alt={ "Microsoft Logo" }
                            src={ service.imgUrl || AzureIcon }
                        />
                    </IconButton>
                </form> 
                : <></>
            }
        </>
    )
}