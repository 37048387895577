import React, { BaseSyntheticEvent, FunctionComponent, useEffect, useState } from "react"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import { SignInStep } from "../flows/SignIn"
import { SignUpStep } from "../flows/SignUp"
import { InvitationStep } from "../flows/Invitation"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState, IUser, IInvitation } from "../store/types"
import { StepMask } from "./StepMask"
import { useIntl } from "react-intl"
import { NewPasswordStep } from "../flows/NewPassword"
import { setLoading } from "../store/actions"
import apiService from "../api"
import qs from "qs"
import {
    useHistory,
    useLocation
} from "react-router-dom"
import { IAPIError } from "../api/types"
import PasswordChecklist from "../components/PasswordChecklist"

type PasswordStepProps = {
    type: "boarding" | "new-password"
    onStepNavigation: (step: NewPasswordStep | SignInStep | SignUpStep | InvitationStep, back: boolean) => void
}

export const ChoosePasswordStep: FunctionComponent<PasswordStepProps> = (props) => {
    const { watch, register, handleSubmit, errors } = useForm()
    const insertedPwd = watch("password")
    const user = useSelector<IStoreState, IUser | undefined>(state => state.user)
    const invitation = useSelector<IStoreState, IInvitation | undefined>(state => state.invitation)
    const [done, setDone] = useState(false)
    const [error, setError] = useState<IAPIError>()
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const location = useLocation()
    const [code, setCode] = useState<string>()
    const [password, setPassword] = useState<string>("")
    const [passwordRetype, setPasswordRetype] = useState<string>("")
    const [isValid, setIsValid] = useState<boolean>(false)
    const history = useHistory()
    const loginURL = process.env.REACT_APP_EXTERNAL_URI

    useEffect(() => {
        const {
            code
        } = qs.parse(location.search, { ignoreQueryPrefix: true })

        if (code && typeof code == "string") {
            setCode(code)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = (data: any, e?: BaseSyntheticEvent) => {
        e?.preventDefault()
        dispatch(setLoading(true))
        if (props.type === "new-password" && code && !done) {
            apiService.newPassword(data.password, data.retypePassword, code)
                .then(() => window.location.assign("" + loginURL))
                .catch(error => {
                    setError(error)
                })
                .finally(() => dispatch(setLoading(false)))
        } else if (props.type === "new-password" && code && done) {
            history.push("/")
        } else if (invitation?.idInvitation && user) {
            apiService.acceptInvitation(invitation?.idInvitation, { 
                userInvitationType: 'NOVAFUTUR', 
                firstName: user?.firstName, 
                lastName: user?.lastName, 
                mobile: user?.mobile, 
                password: data.password, 
                retypePassword: data.retypePassword,
                organizationId: invitation.organization.idOrganization
            })
                .then(() => {
                    setDone(true)
                    history.push("/invite/accepted")
                })
                .catch((e: IAPIError) => {
                    setError(e)
                })
                .finally(() => dispatch(setLoading(false)))
        }
    }

    const renderError = () => {
        if (!!errors.password) return (errors.password as any).message
        else if (error?.code) return intl({ id: "errors.api." + error.code, defaultMessage: "Error" })
        else return " "
    }

    return (
        <StepMask title={ intl({ id: "steps.choosePwd" }) }
                submitDisabled={!isValid}
                submitText={ props.type === "boarding" ? intl({ id: "steps.join" }) : intl({ id: "steps.send" }) }
                handleSubmit={ handleSubmit(onSubmit) }
                secondaryAction={ props.type === "boarding" ? intl({ id: "steps.back" }) : undefined }
                onSecondaryAction={ () => {props.onStepNavigation(InvitationStep.invite, true)} }>
            <TextField
                variant="outlined"
                required={ true }
                fullWidth={ true }
                id="signup-password"
                label={ intl({ id: "choosePwdStep.label" }) }
                name="password"
                type="password"
                onChange={e => setPassword(e.target.value)}
                inputRef={ register({
                    required: intl({ id: "errors.required" }),
                    minLength: {
                        value: 6,
                        message: intl({ id: "choosePwdStep.errors.length" })
                    }
                }) }
                error={ !!errors.password || !!error?.code }
                helperText={ renderError() }
            />
            <Box mt={ 2 }>
                <TextField
                    variant="outlined"
                    required={ true }
                    fullWidth={ true }
                    id="retypePassword"
                    type="password"
                    label={ intl({ id: "choosePwdStep.confirmLabel" }) }
                    name="retypePassword"
                    onChange={e => setPasswordRetype(e.target.value)}
                    inputRef={ register({
                        validate: {
                            required: (value: string) => (value && value.length > 0) || intl({ id: "errors.required" }),
                            matchPwd: (value: string) => {
                                return value === insertedPwd || intl({ id: "errors.pwdMismatch" })
                            }
                        }
                    }) }
                    error={ !!errors.retypePassword }
                    helperText={ (!!errors.retypePassword && (errors.retypePassword as any).message) || " " }
                />
            </Box>
            <PasswordChecklist
				rules={["length","specialChar","number","capital","lowercase","match"]}
				minLength={8}
                value={password}
                valueAgain={passwordRetype}
                onChange={(isPasswordValid) => {
                    setIsValid(isPasswordValid)
                }}
                iconSize={12}
                style={{ marginBottom: 20}}
			/>
        </StepMask>
    )
}