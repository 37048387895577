import React, { FunctionComponent } from "react"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { IntlProvider } from "react-intl"
import locale_en from "./translations/en.json"
import locale_it from "./translations/it.json"

const data: any = {
    'it': locale_it,
    'en': locale_en
};

type IntlWrapperProps = {}

export const IntlWrapper: FunctionComponent<IntlWrapperProps> = (props) => {
    const locale = useSelector((state: IStoreState) => state.locale)

    return (
        <IntlProvider locale={ locale } messages={data[locale]}>
            { props.children }
        </IntlProvider>
    )
}

export const locales = {
    en: "English",
    it: "Italian"
}