import { makeStyles } from "@material-ui/core"

export const useCommonStyles = makeStyles(theme => ({
    imageIcon: {
        height: 48,
        width: 48,
    },
    iconRoot: {
        height: 48,
        width: 48,
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    root: {
        flexGrow: 1,
    },
    buttonLink: {
        cursor: 'pointer',
        fontWeight: 'bold',
        color: '#1973e8'
    },
    form: {
        padding: 1,
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        color: '#fff',
        fontWeight: 'bold',
        textTransform: 'none'
    },
    fullWidth: {
        width: '100%',
        margin: 0,
        padding: 0,
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    permissions: {
        minWidth: 0,
        padding: theme.spacing(0, 0, 0, 0),
    },
    hidden: {
        visibility: 'hidden',
    },
    actions: {
        marginTop: theme.spacing(3)
    }
}));