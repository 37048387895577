import { IAPIError } from "../api/types"

export interface IStoreState {
    loading: boolean
    locale: "en" | "it"
    service: IService
    invitation?: IInvitation
    tenant: string
    continue?: string
    user?: IUser
    error?: IAPIError
    data?: any
}

export enum Provider {
    local = "nova",
    azure = "azure",
    google = "google"
}

export interface IService {
    id: string
    name: string
    imgUrl?: string
    termsUrl?: string
    policyUrl?: string
    codeVerifier?: string
    redirectUri: string
    idOrganisation?: string
    providers: Provider[]
}

export interface IUser {
    firstName?: string
    lastName?: string
    imgUrl?: string
    email?: string
    mobile?: string
    jwt?: string
    logged?: boolean
    otp?: "REQUESTED" | "UNAVAILABLE" | "OFF"
    code?: string
    idLogin?: string
    nextResendOtp?: Date
    organisations?: IOrganization[]
}

export interface IInvitation {
    idInvitation: string
    type: "USER" | "ORGANIZATION"
    organization: {
        idOrganization: string
        name: string
        imgUrl?: string
    }
    email: string
    alreadyRegistered: boolean
    apps: IApplication[]
    roles: IRole[]
    identityProvider: string
    callbackUri: string,
    status: "PENDING" | "ACCEPTED" | "REFUSED" | "DELETED"

}

export interface IApplication {
    idApplication: string
    name: string
    description?: string
    imgUrl?: string
}

export interface IRole {
    idRole: string
    name: string
    description?: string
    idApplication?: string
}


export interface IOrganization {
    idOrganization: string
    name: string
    imgUrl: string
}