import { setUser, setApiRequestPending, setApiRequestFailed, ActionTypes, logout, setInvitation } from "./actions"
import apiService from "../api"
import { Dispatch } from "redux"
import { IStoreState } from "./types"
import { IAPIError, IVerifyOtp, IExternalTokenToCodeExchangeReq } from "../api/types"

export function asyncLogin(email: string, password: string): (dispatch: any, getState: () => IStoreState) => Promise<boolean> {
    
    return (dispatch, getState) => {
        dispatch(setApiRequestPending(true))
        return apiService.login({ email, password, grantType: "authorization_code" })
            .then(response => {
                const data = response.data
                apiService.setJwt(data.access_token)
                if (data.code) {
                    window.location.href = `${ getState().service.redirectUri }?code=${ data.code }`
                }
                dispatch(setUser({
                    email: getState().user?.email || undefined,
                    ...data,
                    logged: true
                }))
                return true
            })
            .catch((ex: IAPIError) => {
                dispatch(setApiRequestFailed(ex))
                return false
            })
            .finally(() => dispatch(setApiRequestPending(false)))
    }
}

export function verifyOtp(data: IVerifyOtp): (dispatch: Dispatch, getState: () => IStoreState) => Promise<boolean> {
    return (dispatch, getState) => {
        dispatch(setApiRequestPending(true))
        return apiService.verifyOtpWithFetch(data)
            .then((resp) => resp.json())
            .then(resData => {
                if(resData.error){
                    if (resData.httpStatus === 401) {
                        dispatch(logout())
                    }
                    dispatch(setApiRequestFailed(resData.error))
                    dispatch(setApiRequestPending(false))
                }
                else if (resData.code) {
                    window.location.href = `${ getState().service.redirectUri }?code=${ resData.code }`
                    return true
                } 
                return false;
            })
            .catch((ex: IAPIError) => {
                dispatch(setApiRequestFailed(ex))
                dispatch(setApiRequestPending(false))
                return false
            })
    }
}

export function asyncResendOtp(idOtp: string): (dispatch: Dispatch<ActionTypes>, getState: () => IStoreState) => Promise<boolean> {
    return (dispatch, getState) => {
        dispatch(setApiRequestPending(true))
        return apiService.resendOtp(idOtp)
            .then(response => {
                const data: any = response.data
                let nextSentDate: Date
                if (data.nextResendUntil) {
                    nextSentDate = new Date(data.nextResendUntil)
                } else {
                    let dt = new Date()
                    dt.setSeconds(dt.getSeconds() + 30)
                    nextSentDate = dt
                }
                dispatch(setUser({
                    ...getState().user,
                    idLogin: data.idLogin,
                    nextResendOtp: nextSentDate
                }))
                return true
            })
            .catch((ex: IAPIError) => {
                dispatch(setApiRequestFailed(ex))
                return false
            })
            .finally(() => dispatch(setApiRequestPending(false)))
    }
}

export function asyncGetInvitation(idInvitation: string): (dispatch: Dispatch<ActionTypes>, getState: () => IStoreState) => void {
    return (dispatch, getState) => {
        dispatch(setApiRequestPending(true))
        return apiService.getInvitation(idInvitation)
            .then(response => {
                console.log("response is: ", response)
                dispatch(setInvitation(response.data))
            })
            .catch((ex: IAPIError) => {
                dispatch(setApiRequestFailed(ex))
            })
            .finally(() => dispatch(setApiRequestPending(false)))
    }
}

export function asyncExchangeCode(code: string, provider: "azure"): (dispatch: Dispatch, getState: () => IStoreState) => Promise<boolean> {
    return (dispatch, getState) => {
        dispatch(setApiRequestPending(true))
        switch (provider) {
            case "azure":
            default:
                return apiService.exchangeAzureCode({
                    client_id: process.env.REACT_APP_AZURE_CLIENT_ID || "",
                    code,
                    scope: encodeURIComponent('openid'),
                    redirect_uri: process.env.REACT_APP_AZURE_REDIRECT || "",
                    grant_type: "authorization_code",
                    code_verifier: localStorage.getItem("azure_code_challenge") || "",
                })
                    .then(async response => {
                        if (response.data.access_token) {
                            apiService.clientCredentials()
                            .then(async ({ data }: any) => {
                                if (data && data.access_token) {
                                    let x = getState().service
                                    console.log(x)
                                    const req : IExternalTokenToCodeExchangeReq = {
                                        token_id: response.data.id_token,
                                        provider,
                                        organization_id: getState().service.idOrganisation || ""
                                    }
                                    apiService.exchangeAzureJWTForSFCode(req, data.access_token)
                                    .then(async ({ data }: any) => {
                                        if (data && data.code) {
                                            window.location.href = `${ getState().service.redirectUri }?code=${ data.code }`
                                        }
                                        return true
                                    }).catch((ex: IAPIError) => {
                                        dispatch(setApiRequestFailed(ex))
                                        return false
                                    })
                                }
                            }).catch((ex: IAPIError) => {
                                dispatch(setApiRequestFailed(ex))
                                return false
                            })
                        }
                        return true
                    })
                    .catch((ex: IAPIError) => {
                        if (ex.statusCode === 401) {
                            dispatch(logout())
                        }
                        dispatch(setApiRequestFailed(ex))
                        dispatch(setApiRequestPending(false))
                        return false
                    })

        }
    }
}


export function asyncGoogleLogin(code: string, provider: "google"): (dispatch: Dispatch, getState: () => IStoreState) => Promise<boolean> {

    return (dispatch, getState) => {
        dispatch(setApiRequestPending(true))
        return apiService.exchangeGoogleCode({
            code,
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
            client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET || "",
            redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT || "",
            grant_type: "authorization_code"
        })
        .then(async response => {
            if (response.data.access_token) {
                apiService.clientCredentials()
                .then(async ({ data }: any) => {
                    if (data && data.access_token) {
                        const req : IExternalTokenToCodeExchangeReq = {
                            token_id: response.data.id_token,
                            provider,
                            organization_id: getState().service.idOrganisation || ""
                        }
                        apiService.googleLogin(req, data.access_token)
                        .then((resp) => resp.json())
                        .then(data => {
                            if(data.error != null) {
                                window.location.href = process.env.REACT_APP_EXTERNAL_REDIRECT_URI_ERROR || "/";
                                return false
                            }
                            apiService.setJwt(data.access_token)
                            dispatch(setUser({
                                email: getState().user?.email || undefined,
                                ...data,
                                logged: true
                            }))
                            return true
                        })
                        .catch((ex: IAPIError) => {
                            dispatch(setApiRequestFailed(ex))
                            return false
                        })
                        .finally(() => dispatch(setApiRequestPending(false)))
                    }
                }).catch((ex: IAPIError) => {
                    dispatch(setApiRequestFailed(ex))
                    return false
                })
            }
            return true
        })
        .catch((ex: IAPIError) => {
            if (ex.statusCode === 401) {
                dispatch(logout())
            }
            dispatch(setApiRequestFailed(ex))
            dispatch(setApiRequestPending(false))
            return false
        })

    }
}