import React, { useState, FunctionComponent, BaseSyntheticEvent } from "react"
import TextField from "@material-ui/core/TextField"
import { SignInStep } from "../flows/SignIn"
import { StepMask } from "./StepMask"
import { useForm } from "react-hook-form"
import { setLoading } from "../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useIntl } from "react-intl"
import { parsePhoneNumberFromString as parseMobile } from "libphonenumber-js/max"
import apiService from "../api"
import { IStoreState } from "../store/types"
import { Typography } from "@material-ui/core"
import DoneImg from "../assets/done.svg"

type RecoverPasswordStepProps = {
    onStepNavigation: (step: SignInStep, back: boolean) => void
}

export const RecoverPasswordStep: FunctionComponent<RecoverPasswordStepProps> = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const dispatch = useDispatch()
    const { formatMessage: intl } = useIntl()
    const loading = useSelector<IStoreState, boolean>((state) => state.loading)
    const [error, setError] = useState<any>()
    const [done, setDone] = useState(false)

    const onSubmit = async (data: any, e?: BaseSyntheticEvent) => {
        e?.preventDefault()
        try {
            setError(undefined)
            dispatch(setLoading(true))
            await apiService.sendForgot(data.recoverEmail)
            setDone(true)
        } catch (ex) {
            setError(ex)
        }
        dispatch(setLoading(false))
    }

    const renderError = () => {
        if (!!errors.recoverEmail) return (errors.recoverEmail as any).message
        else if (error?.code) return intl({ id: `errors.api.${ error.code }`, defaultMessage: "Error" })
        else return " "
    }

    return (
        <StepMask title={ intl({ id: "recPwdStep.title" }) }
                  subtitle={ !done ? intl({ id: "recPwdStep.subtitle" }) : undefined }
                  secondaryAction={ intl({ id: "steps.back" }) } onSecondaryAction={ () => {
            setLoading(!loading)
            props.onStepNavigation(SignInStep.password, true)
        } } showSubmit={ !done } submitText={ intl({ id: "steps.send" }) } handleSubmit={ handleSubmit(onSubmit) }>
            { done ? (<>
                    <img width={ 200 } alt="" src={ DoneImg }/>
                    <Typography>{ intl({ id: "recoverPwdStep.success" }) }</Typography>
                </>) :
                (<TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="recoverEmail"
                    label={ intl({ id: "recPwdStep.label" }) }
                    name="recoverEmail"
                    autoComplete="email"
                    inputRef={ register({
                        validate: {
                            required: (value: string) => (value && value.length > 0) || intl({ id: "errors.required" }),
                            phoneNumberOrEmail: (value: string) => {
                                let regExpEmail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                return regExpEmail.test(value) || parseMobile(value)?.isValid() || intl({ id: "errors.invalidEmailPhone" })
                            }
                        }
                    }) }
                    error={ !!errors.recoverEmail || !!error?.code }
                    helperText={ renderError() }
                />) }
        </StepMask>)
}