import React, { FunctionComponent, useEffect, useState } from "react"
import { useCommonStyles } from "../utils/styles"
import clsx from "clsx"
import { createStyles, makeStyles, Typography } from "@material-ui/core"
import { useIntl } from "react-intl"

const useStyles = makeStyles(() =>
    createStyles({
        disabled: {
            color: "grey",
            fontWeight: "lighter"
        }
    })
)

type NoMatchContentProps = {
    nextDate: Date
    loading: boolean
    onClick: (e: any) => void
    btnText: string
}

export const LinkCountdown: FunctionComponent<NoMatchContentProps> = (props) => {
    const commonStyles = useCommonStyles()
    const classes = useStyles()
    const startDate = new Date()
    const { formatMessage: intl } = useIntl()

    const calculateTimeLeft = () => {
        const difference = +props.nextDate - +startDate
        return Math.floor((difference / 1000))
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
        if (timeLeft >= 1) {
            setTimeout(() => {
                setTimeLeft(timeLeft - 1)
            }, 1000)
        }

    })

    useEffect(() => {
        setTimeLeft(calculateTimeLeft)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nextDate])

    return (
        <Typography
            className={ clsx({ [commonStyles.buttonLink]: true, [classes.disabled]: timeLeft >= 1 || props.loading }) }
            onClick={ (e: any) => {
                e.preventDefault()
                if (timeLeft <= 0 && !props.loading) props.onClick(e)
            } }>
            { props.btnText }{ timeLeft >= 1 ? intl({ id: "otpOrgStep.resendTimeLeft" }, { time: timeLeft}) : "" }
        </Typography>
    )
}