import React, { BaseSyntheticEvent, FunctionComponent } from "react"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import { SignInStep } from "../flows/SignIn"
import { SignUpStep } from "../flows/SignUp"
import { InvitationStep } from "../flows/Invitation"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState, IUser } from "../store/types"
import { StepMask } from "./StepMask"
import { useIntl } from "react-intl"
import { NewPasswordStep } from "../flows/NewPassword"
import { setUser } from "../store/actions"

type PersonalInfoStepProps = {
    type: "boarding" | "signup"
    onStepNavigation: (step: NewPasswordStep | SignInStep | SignUpStep | InvitationStep, back: boolean) => void
}

export const PersonalInfoStep: FunctionComponent<PersonalInfoStepProps> = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const user = useSelector<IStoreState, IUser | undefined>(state => state.user)
    const { formatMessage: intl } = useIntl()
    const dispatch = useDispatch()
    const onSubmit = (data: any, e?: BaseSyntheticEvent) => {
        e?.preventDefault()
        if (props.type === "boarding") {
            dispatch(setUser({
                ...user, firstName: data.firstName, lastName: data.lastName, mobile: data.mobile
            }))
            props.onStepNavigation(InvitationStep.password, false)
        }
    }

    return (
        <StepMask title={ intl({ id: "steps.personalInfo" }) }
                  submitText={ props.type === "boarding" ? intl({ id: "steps.next" }) : intl({ id: "steps.next" }) }
                  handleSubmit={ handleSubmit(onSubmit) }
                  secondaryAction={ props.type === "boarding" ? intl({ id: "steps.back" }) : undefined }
                  onSecondaryAction={ () => {props.onStepNavigation(InvitationStep.invite, true)} }>
            <TextField
                size="small"
                variant="outlined"
                required
                fullWidth
                id="first-name"
                label={ intl({ id: "personalInfoStep.firstName" }) }
                name="firstName"
                inputRef={ register({
                    required: intl({ id: "errors.required" })
                }) }
                error={ !!errors.firstName }
                helperText={ (!!errors.firstName && (errors.firstName as any).message) || " " }
            />
            <Box mt={ 2 }>
                <TextField
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    id="last-name"
                    label={ intl({ id: "personalInfoStep.lastName" }) }
                    name="lastName"
                    inputRef={ register({
                        required: intl({ id: "errors.required" })
                    }) }
                    error={ !!errors.lastName }
                    helperText={ (!!errors.lastName && (errors.lastName as any).message) || " " }
                />
            </Box>
            <Box mt={ 2 }>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="mobile"
                    label={ intl({ id: "personalInfoStep.mobile" }) }
                    name="mobile"
                    error={ !!errors.mobile }
                    helperText={ (!!errors.mobile && (errors.mobile as any).message) || " " }
                />
            </Box>
        </StepMask>
    )
}