import React, { BaseSyntheticEvent, ChangeEvent, useEffect, useRef, useState } from "react"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { IStoreState, IUser } from "../store/types"
import { logout } from "../store/actions"
import { StepMask } from "./StepMask"
import { useIntl } from "react-intl"
import { Avatar, createStyles, FormControl, FormHelperText, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core"
import { asyncResendOtp, verifyOtp } from "../store/thunks"
import { LinkCountdown } from "../components/LinkCountdown"
import { IAPIError, IVerifyOtp } from "../api/types"
import { stringToColor } from "../utils"


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: "100%"
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        }
    })
)

export const GoogleChooseOrganization = () => {
    const { register, handleSubmit, errors, reset, setValue } = useForm<IVerifyOtp>()
    const user = useSelector<IStoreState, IUser | undefined>(state => state.user)
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const error = useSelector<IStoreState, IAPIError | undefined>(state => state.error)
    const dispatch = useDispatch()
    const { formatMessage: intl } = useIntl()
    const [organisationId, setOrganisationId] = useState((user?.organisations && user?.organisations[0].idOrganization) || "")
    const inputLabel = useRef<HTMLLabelElement>(null)
    const [labelWidth, setLabelWidth] = useState(0)

    useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth)
    }, [])

    const classes = useStyles()

    const onSubmit = async (data: IVerifyOtp, e?: BaseSyntheticEvent) => {
        e?.preventDefault()
        dispatch(verifyOtp({ idOrganisation: organisationId, otp: data.otp || "-1", idLogin: user?.idLogin || "" }))
    }

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        setOrganisationId(event.target.value as string)
        setValue("organisationId", event.target.value as string, true)
    }

    const logOut = (e: any) => {
        e.preventDefault()
        dispatch(logout())
        window.open(process.env.REACT_APP_EXTERNAL_REDIRECT_URI_ERROR, '_self')
    }

    const renderError = () => {
        if (!!errors.otp) return (errors.otp as any).message
        else if (error?.code) return intl({ id: `errors.api.${ error.code }`, defaultMessage: "Error" })
        else return " "
    }

    useEffect(() => {
        if (!user) {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return (
        <StepMask title=""
                  subtitle={ user && user.otp === "REQUESTED" ? intl({ id: "otpOrgStep.lastStep.otp&org" }) : intl({ id: "otpOrgStep.lastStep.org" }) }
                  submitText={ intl({ id: "steps.login" }) }
                  handleSubmit={ handleSubmit(onSubmit) } secondaryAction={ intl({ id: "steps.logout" }) }
                  onSecondaryAction={ logOut }>
            { user && user.otp === "REQUESTED" ? (<><TextField
                variant="outlined"
                required={ true }
                fullWidth={ true }
                id="otp"
                label={ intl({ id: "otpOrgStep.otpLabel" }) }
                name="otp"
                inputRef={ register({
                    required: intl({ id: "errors.required" })
                }) }
                error={ !!errors.otp || !!error }
                helperText={ renderError() }
            />
                <Box textAlign="left">
                    <LinkCountdown nextDate={ user?.nextResendOtp || new Date() } onClick={ () => {
                        if (user?.idLogin) {
                            dispatch(asyncResendOtp(user?.idLogin))
                        }
                    } } btnText={ intl({ id: "otpOrgStep.resend" }) } loading={ loading }/>
                </Box></>) : undefined }
            <Box mt={ 4 } textAlign="left">
                <FormControl variant="outlined" className={ classes.formControl }>
                    <InputLabel ref={ inputLabel } id="org-select-label">
                        { intl({ id: "otpOrgStep.orgLabel" }) }
                    </InputLabel>
                    <Select
                        labelId="org-select-label"
                        id="org-select"
                        value={ organisationId }
                        onChange={ handleChange }
                        labelWidth={ labelWidth }
                        fullWidth
                        renderValue={ (value: unknown) => {
                            if (user?.organisations) {
                                const org = user?.organisations.find(org => org.idOrganization === value)
                                if (org) return (
                                    <Grid container justify="flex-start" alignItems="center">
                                        <Avatar
                                            style={ { marginRight: 10, fontSize: 12, width: 24, height: 24, backgroundColor: stringToColor(org.name || "") } }
                                            variant="circle"
                                            src={ org.imgUrl }>{ org.name.charAt(0) }</Avatar>
                                        { org.name }
                                    </Grid>
                                )
                            }
                            return (<></>)
                        } }
                    >
                        {
                            user?.organisations && user?.organisations.map((org) => (
                                <MenuItem key={ org.idOrganization } value={ org.idOrganization }>
                                    <Avatar
                                        style={ { marginRight: 10, fontSize: 12, backgroundColor: stringToColor(org.name || "") } }
                                        variant="circle"
                                        src={ org.imgUrl }>{ org.name.charAt(0) }</Avatar>
                                    { org.name }
                                </MenuItem>
                            ))
                        }
                    </Select>
                    <FormHelperText
                        error={ !!error }>{ !!error && intl({ id: `errors.api.${ error.code }`})}</FormHelperText>
                </FormControl>
            </Box>
        </StepMask>
    )
}