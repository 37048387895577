import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import qs from "qs";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Loader } from "../components/Loader";
import { GoogleChooseOrganization } from "../steps/GoogleChooseOrganization";
import { setService } from "../store/actions";
import { asyncGoogleLogin } from "../store/thunks";
import { IStoreState } from "../store/types";
import { MainContainer } from "./MainContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    position: "absolute",
    width: 120,
    height: 120,
    marginTop: "-16px",
  },
  loadingText: {
    marginTop: "10px",
  },
}));

type ExchangeContainerProps = {};

export const ExchangeGoogleCode: FunctionComponent<ExchangeContainerProps> = (
  props
) => {
  const location = useLocation();
  const user = useSelector((state: IStoreState) => state.user);
  const service = useSelector((state: IStoreState) => state.service);
  const [username] = useState("");
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const classes = useStyles();

  useEffect(() => {
    const { state } = qs.parse(location.search, { ignoreQueryPrefix: true });
    let urlState = state || "";
    const stateParams: string[] = decodeURIComponent(urlState.toString()).split(
      ","
    );

    dispatch(
      setService({
        ...service,
        redirectUri: stateParams[0],
        idOrganisation: stateParams[1],
      })
    );

    const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (code && typeof code == "string") {
      dispatch(asyncGoogleLogin(code, "google"));
    } else {
      //TODO error handling
      //history.push("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return user ? (
    <MainContainer>
      <GoogleChooseOrganization />
    </MainContainer>
  ) : (
    <Container className={classes.root} component="main">
      <Loader />
      <Typography className={classes.loadingText}>
        {intl({ id: "steps.social_login.exchange" }, { name: username || "" })}
      </Typography>
    </Container>
  );
};
