export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const stringToColor = (name: string) => {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = "#"

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${ value.toString(16) }`.substr(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}
