import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Typography } from "@material-ui/core";
import clsx from 'clsx';
import { useIntl } from "react-intl"

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        marginTop: '16px',
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center',
            marginTop: '0px',
        }
    },
    columnCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));


type NoMatchContentProps = {
    img: string
    titleId: string
}

const NoMatchContent: FunctionComponent<NoMatchContentProps> = (props) => {
    const classes = useStyles();
    const {formatMessage: intl } = useIntl();

    return (
        <Container className={clsx(classes.root, classes.columnCenter)} component="main">
            <img alt="Not Found" width="350" src={props.img} />
            <Box mt={2}>
                <Typography component="h1" variant="h5">
                    {intl({id: props.titleId})}
                </Typography>
            </Box>

        </Container >
    );
}

type NoMatchProps = {
}

export const NoMatch: FunctionComponent<NoMatchProps> = (props) => {
    const notFoundPages = [
        { img: "not_found.svg", titleId: "notfound.first" },
        { img: "not_found_search.svg", titleId:"notfound.second" },
        { img: "not_found_taken.svg", titleId: "notfound.third" },
    ]

    const classes = useStyles();

    const randomize = () => {
        return notFoundPages[Math.floor(Math.random() * notFoundPages.length)];
    }

    return (
        <Container className={clsx(classes.root, classes.columnCenter)} component="main">
            <NoMatchContent {...randomize()} />
        </Container >
    );
}