import client from "./config"
import { AxiosPromise, Method } from "axios"
import { IInvitation, IUser } from "../store/types"
import { IAPIUser, IExternalTokenToCodeExchangeReq, IAzureExchangeCodeRequest, IBoardingUser, ICredentials, IResendOtpResponse, IVerifyOtp, IGoogleExchangeCodeRequest, IGoogleExchangeCodeResponse, IExternalProviderExchangeCodeResponse } from "./types"
import qs from "qs"

class ApiService {

    clientCredentials() {
        return this._performRequest("post", "oauth/token", qs.stringify({
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            grant_type: "client_credentials",
            id_organization: process.env.REACT_APP_ORG_ID
        }),{
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        )
    }

    signUp(user: IUser) {
        return this._performRequest("post", "signup", user)
    }

    login(credentials: ICredentials): AxiosPromise<IAPIUser> {
        return this._performRequest("post", "authentication/login", credentials)
    }

    resendOtp(idLogin: string): AxiosPromise<IResendOtpResponse> {
        return this._performRequest("post", `authentication/resendotp/${ idLogin }`)
    }

    sendForgot(email: string) {
        return this._performRequest("post", "resources/users/forgot-password", { email })
    }

    newPassword(
        newPassword: string,
        newPasswordRetype: string,
        idUser: string
    ) {
        return this._performRequest("post", "resources/users/forgot-password-change", 
        { newPassword: newPassword, newPasswordRetype: newPasswordRetype, idUser })
    }

    getInvitation(idInvitation: string): AxiosPromise<IInvitation> {
        return this._performRequest("get", `resources/invitations/${ idInvitation }`)
    }

    acceptInvitation(idInvitation: string, data?: IBoardingUser) {
        return this._performRequest("post", `resources/invitations/${ idInvitation }/accept`, data)
    }

    denyInvitation(idInvitation: string) {
        return this._performRequest("post", `resources/invitations/${ idInvitation }/deny`)
    }

    getMe() {
        return this._performRequest("get", "users/me")
    }

    verifyOtp(data: IVerifyOtp) {
        return this._performRequest("post", "authentication/completeotp", data)
    }

    verifyOtpWithFetch(data: IVerifyOtp) {
        return fetch( `${process.env.REACT_APP_API_ENDPOINT}authentication/completeotp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': client.defaults.headers.common["Authorization"]
            },
            credentials: 'include',
            body: JSON.stringify(data)
          })
    }

    setJwt(jwt: string) {
        client.defaults.headers.common["Authorization"] = `Bearer ${ jwt }`
    }

    exchangeAzureCode(data: IAzureExchangeCodeRequest): AxiosPromise<IExternalProviderExchangeCodeResponse> {
        return this._performRequest("post", `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/v2.0/token`, qs.stringify(data), {
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    }

    exchangeAzureJWTForSFCode(req: IExternalTokenToCodeExchangeReq, clientCredentialsToken: string): AxiosPromise<IExternalProviderExchangeCodeResponse> {
        return this._performRequest("post", `oauth/rest/token:exchange`, req, {
                Authorization: clientCredentialsToken
            }
        )
    }

    googleLogin(req: IExternalTokenToCodeExchangeReq, clientCredentialsToken: string) {
        return fetch( `${process.env.REACT_APP_API_ENDPOINT}oauth/rest/external-login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': clientCredentialsToken
            },
            credentials: 'include',
            body: JSON.stringify(req)
          })
    }

    exchangeGoogleJWTForSFCodeFetch(req: IExternalTokenToCodeExchangeReq, clientCredentialsToken: string) {
        return fetch( `${process.env.REACT_APP_API_ENDPOINT}oauth/rest/token:exchange`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': clientCredentialsToken
            },
            credentials: 'include',
            body: JSON.stringify(req)
          })
    }

    exchangeGoogleCode(data: IGoogleExchangeCodeRequest): AxiosPromise<IGoogleExchangeCodeResponse> {
        return this._performRequest("post", `https://oauth2.googleapis.com/token`, qs.stringify(data), {
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    }

    exchangeGoogleJWTForSFCode(req: IExternalTokenToCodeExchangeReq, clientCredentialsToken: string): AxiosPromise<IExternalProviderExchangeCodeResponse> {
        return this._performRequest("post", `oauth/rest/token:exchange`, req, {
                Authorization: clientCredentialsToken
            }
        )
    }

    _performRequest(
        method: Method,
        url: string,
        params?: object | string,
        headers?: any
    ): AxiosPromise {
        const body = method === "get" ? "params" : "data"
        const requestConfig = {
            method,
            url,
            [body]: params || {},
            headers
        }

        return client.request(requestConfig)
    }
}

export default new ApiService()