import axios from "axios"
import { IAPIError } from "./types"

const baseURL = process.env.REACT_APP_API_ENDPOINT

const getCookies = (): { [id: string]: any } =>
    document.cookie.split(";").reduce((cookies: { [id: string]: any }, item) => {
        const [name, value] = item.split("=")
        cookies[name] = value
        return cookies
    }, {})


const ajax = axios.create({
    baseURL,
    timeout: 40000
    //withCredentials: true should be set
})

// Add a request interceptor
ajax.interceptors.request.use(function (config) {
    if (typeof document != "undefined" && document.cookie) {
        const xsrfToken = getCookies()["xsrfToken"]
        // CSRF Token.
        if (xsrfToken) config.headers["X-XSRF-TOKEN"] = xsrfToken
    }
    return config
})

// Add a response interceptor
ajax.interceptors.response.use((response) => {
    return response
}, function (error) {
    // Do something with response error
    if (error.response && error.response.status === 401) {
        console.log("unauthorized, logging out ...")
        return Promise.reject({
            statusCode: error.response.status,
            data: error.response.data
        } as IAPIError)
    }
    if (error.response && error.response.data) {
        return Promise.reject({
            statusCode: error.response.status,
            ...error.response.data.error,
            data: error.response.data
        } as IAPIError)
    }
    return Promise.reject({
        statusCode: -1
    } as IAPIError)
})

export default ajax