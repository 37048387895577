import React, { FunctionComponent } from "react"
import { EmailPhoneStep } from "../steps/EmailPhoneStep"
import { SignInStep } from "./SignIn"
import { InvitationStep } from "./Invitation"
import { ChoosePasswordStep } from "../steps/ChoosePasswordStep"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { NewPasswordStep } from "./NewPassword"

type SignUpFlowProps = {}

export enum SignUpStep {
    email = "email",
    password = "password",
}

export const SignUpFlow: FunctionComponent<SignUpFlowProps> = (props) => {
    const location = useLocation()
    const history = useHistory()

    function handleStepNavigation(step: NewPasswordStep | SignUpStep | SignInStep | InvitationStep, back = false) {
        const transition = back ? "slideback" : "slide"
        switch (step) {
            case SignUpStep.email:
                history.push("/signup/id", { transition })
                break
            case SignUpStep.password:
                history.push("/signup/pwd", { transition })
                break
            default:
                history.push("/signup/id", { transition })
        }
    }

    return (
        <Switch location={ location }>
            <Route path="/signup/id">
                <EmailPhoneStep onStepNavigation={ handleStepNavigation } type={ "signup" }/>
            </Route>
            <Route path="/signup/pwd">
                <ChoosePasswordStep type="boarding" onStepNavigation={ handleStepNavigation }/>
            </Route>
        </Switch>
    )
}
