import React, { FunctionComponent, useEffect } from "react"
import "./App.css"
import { SignInFlow } from "./flows/SignIn"
import { MainContainer } from "./containers/MainContainer"
import { ThemeProvider, createMuiTheme } from "@material-ui/core"
import { SignUpFlow } from "./flows/SignUp"
import { ExchangeAzureCode } from "./containers/ExchangeAzureCode"
import { FinalizeBoardingFlow } from "./flows/Invitation"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from "react-router-dom"
import { NoMatch } from "./components/NoMatch"
import store from "./store"
import { Provider, useDispatch } from "react-redux"
import { IntlWrapper } from "./intl"
import { NewPasswordFlow } from "./flows/NewPassword"
import { setLocale } from "./store/actions"
import { IdentityProviderChoice } from "./containers/IdentityProviderChoice"
import qs from "qs"
import { ExchangeGoogleCode } from "./containers/ExchangeGoogleCode"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2A6EFB',
        },
        secondary: {
            main: '#2A6EFB',
        }
    }
})

type RoutingComponentProps = {}

export const RoutingComponent: FunctionComponent<RoutingComponentProps> = () => {
    let location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (navigator.language) {
            const userLang = navigator.language.split("-")[0]
            switch (userLang) {
                case "it":
                    dispatch(setLocale("it"))
                    break
                case "en":
                default:
                    dispatch(setLocale("en"))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Switch location={ location }>
                <Route path="/azure_callback">
                    <ExchangeAzureCode/>
                </Route>
                <Route path="/google_callback">
                    <ExchangeGoogleCode/>
                </Route>
                <Route path="/signup">
                    <SignUpFlow/>
                </Route>
                <Route path="/invite">
                    <FinalizeBoardingFlow/>
                </Route>
                <Route path="/new-password">
                    <NewPasswordFlow/>
                </Route>
                <Route path="/">
                    <SignInFlow/>
                </Route>
            </Switch>
        </>
    )
}

const App: React.FC = () => {

    const cssRuleTitle =
        "color: rgb(249, 0, 0);" +
        "font-size: 60px;" +
        "font-weight: bold;" +
        "text-shadow: 1px 1px 5px rgb(249, 0, 0);" +
        "filter: dropshadow(color=rgb(249, 0, 0), offx=1, offy=1);"

    setTimeout(console.log.bind(console, "%cStop!", cssRuleTitle), 0)

    const cssRuleMessage =
        "color: rgb(0, 0, 0);" +
        "font-size: 20px;" +
        "font-weight: bold;" +
        "background-color: #ffffff;"
    setTimeout(console.log.bind(console, "%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.Do not enter or paste code that you do not understand!", cssRuleMessage), 0)

    const { providers } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    return (
        <Router>
            <Provider store={ store }>
                <IntlWrapper>
                    <ThemeProvider theme={ theme }>
                        <div className="App">
                            { process.env.REACT_APP_DEV_BANNER === "true" ? <div
                                style={ { width: 300, position: "absolute", top: 50, left: -80, textAlign: "center", letterSpacing: 1, color: "rgb(240, 240, 240)", transform: "rotate(-45deg)", zIndex: 9999, fontSize: 16, background: "rgb(238, 68, 51)" } }>DEVELOPMENT</div> : null }
                            <Switch>
                                <Route path="/(signin|boarding|refused|new-password)">
                                    <MainContainer animation={ false }>
                                        <RoutingComponent/>
                                    </MainContainer>
                                </Route>
                                <Route path="/invite">
                                    <MainContainer>
                                        <RoutingComponent/>
                                    </MainContainer>
                                </Route>
                                <Route path="/oauth_callback_azure">
                                    <ExchangeAzureCode />
                                </Route>
                                <Route path="/oauth_callback_google">
                                    <ExchangeGoogleCode/>
                                </Route>
                                <Route path="/">
                                    <IdentityProviderChoice providers={providers}>
                                        <RoutingComponent/>
                                    </IdentityProviderChoice>
                                </Route>
                                <Route component={ NoMatch }/>
                            </Switch>
                        </div>
                    </ThemeProvider>
                </IntlWrapper>
            </Provider>
        </Router>
    )
}

export default App
