import React, { FunctionComponent, useEffect } from "react"
import { EmailPhoneStep } from "../steps/EmailPhoneStep"
import { PasswordStep, PasswordStepType } from "../steps/PasswordStep"
import { RecoverPasswordStep } from "../steps/RecoverPasswordStep"
import { SignUpStep } from "./SignUp"
import { InvitationStep } from "./Invitation"
import { useDispatch } from "react-redux"
import qs from "qs"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { OtpChooseOrganizationStep } from "../steps/OtpChooseOrganizationStep"
import { setService } from "../store/actions"
import { IService, Provider } from "../store/types"

type SignInFlowProps = {}

export enum SignInStep {
    email = "email",
    password = "password",
    otp = "otp",
    recover = "recover"
}

export const SignInFlow: FunctionComponent<SignInFlowProps> = () => {

    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {

        if(location.pathname === '/') {
            const {
                id,
                name,
                imgUrl,
                termsUrl,
                policyUrl,
                codeVerifier,
                redirectUrl,
                idOrganisation,
                providers
            } = qs.parse(location.search, { ignoreQueryPrefix: true })

            let service: IService = {
                id: "nova_auth",
                name: process.env.REACT_APP_AUTH_SERVICE_NAME || "",
                redirectUri: process.env.REACT_APP_AUTH_WEBAPP || "",
                policyUrl: "",
                termsUrl: "",
                idOrganisation: "",
                providers: []
            }

            if (id && typeof id == "string") {
                service.id = id
                service.name = typeof name == "string" ? name : process.env.REACT_APP_AUTH_SERVICE_NAME || ""
                service.imgUrl = typeof imgUrl == "string" ? imgUrl : ""
            }
    
            if (codeVerifier && typeof codeVerifier == "string") {
                service.codeVerifier = codeVerifier
            }
    
            if (redirectUrl && typeof redirectUrl == "string") {
                service.redirectUri = redirectUrl
            }
    
            if (idOrganisation && typeof idOrganisation == "string") {
                service.idOrganisation = idOrganisation
            }
    
            if (termsUrl && typeof termsUrl == "string") {
                service.termsUrl = termsUrl
            }
    
            if (policyUrl && typeof policyUrl == "string") {
                service.policyUrl = policyUrl
            }
    
            if (providers && typeof providers == "string") {
                service.providers = providers.split(",") as Provider[]
            }
    
            console.log(service.redirectUri)
            dispatch(setService(service))

        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]) 




    function handleStepNavigation(nextStep: SignInStep | SignUpStep | InvitationStep, back = false) {
        const transition = back ? "slideback" : "slide"
        switch (nextStep) {
            case SignInStep.email:
                history.push("/", { transition })
                break
            case SignInStep.password:
                history.push("/signin/pwd", { transition })
                break
            case SignInStep.otp:
                history.push("/signin/otporg", { transition })
                break
            case SignInStep.recover:
                history.push("/signin/recpwd", { transition })
                break
            default:
                history.push("/", { transition })
        }
    }

    return (
        <Switch location={ location }>
            <Route exact path="/">
                <EmailPhoneStep onStepNavigation={ handleStepNavigation } type="signin"/>
            </Route>
            <Route path="/signin/pwd">
                <PasswordStep onStepNavigation={ handleStepNavigation } type={ PasswordStepType.password }/>
            </Route>
            <Route path="/signin/otporg">
                <OtpChooseOrganizationStep onStepNavigation={ handleStepNavigation }/>
            </Route>
            <Route path="/signin/recpwd">
                <RecoverPasswordStep onStepNavigation={ handleStepNavigation }/>
            </Route>
        </Switch>
    )
}