import React, { BaseSyntheticEvent, FunctionComponent } from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { Grid } from "@material-ui/core"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import { useCommonStyles } from "../utils/styles"

type StepMaskProps = {
    title: string
    subtitle?: string
    secondaryAction?: string
    onSecondaryAction?: (e: any) => void
    showSubmit?: boolean
    submitText: string
    handleSubmit: (e: BaseSyntheticEvent) => Promise<void>
    submitDisabled?: boolean
}

export const StepMask: FunctionComponent<StepMaskProps> = (props) => {
    const classes = useCommonStyles()
    const loading = useSelector((state: IStoreState) => state.loading)

    return (
        <Box style={ { maxWidth: "370px", width: "100%", display: 'flex', justifyContent: "flex-start" , flexDirection: "column"} }>
            <Typography component="h1" variant="h5">
                { props.title }
            </Typography>
            <Typography component="h2" variant="subtitle1">
                { props.subtitle }
            </Typography>
            <form className={ classes.form } onSubmit={ props.handleSubmit } noValidate>
                { props.children }
                <Grid className={ classes.actions } container justify="space-between" alignItems="center">
                    <Grid item>
                        <Box textAlign="left">
                            { props.secondaryAction && (
                                <Typography className={ classes.buttonLink } onClick={ props.onSecondaryAction }>
                                    { props.secondaryAction }
                                </Typography>)
                            }
                        </Box>
                    </Grid>
                    
                    { props.showSubmit && (<Grid item xs={ 4 }>
                        <Button
                            disabled={ loading || props.submitDisabled }
                            fullWidth={ true }
                            variant="contained"
                            color="primary"
                            className={ classes.submit }
                            type="submit"
                        >
                            { props.submitText }
                        </Button>
                    </Grid>)
                    }
                </Grid>
            </form>
        </Box>
    )
}

StepMask.defaultProps = {
    showSubmit: true
}