import React, { FunctionComponent } from "react"
import { SignInStep } from "./SignIn"
import { InvitationStep } from "./Invitation"
import { ChoosePasswordStep } from "../steps/ChoosePasswordStep"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { SignUpStep } from "./SignUp"

type NewPasswordFlowProps = {}

export enum NewPasswordStep {
    password = "password",
}

export const NewPasswordFlow: FunctionComponent<NewPasswordFlowProps> = (props) => {
    const location = useLocation()
    const history = useHistory()

    function handleStepNavigation(step: NewPasswordStep | SignInStep | InvitationStep | SignUpStep, back = false) {
        const transition = back ? "slideback" : "slide"
        history.push("/new-password", { transition })
    }

    return (
        <Switch location={ location }>
            <Route path="/new-password">
                <ChoosePasswordStep type="new-password" onStepNavigation={ handleStepNavigation }/>
            </Route>
        </Switch>
    )
}
