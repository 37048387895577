import React, { FunctionComponent } from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import Button from "@material-ui/core/Button"
import InvitationAcceptedImg from "../assets/invitation_accepted.svg"
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    }
}))

type InvitationAcceptedProps = {}

export const InvitationAccepted: FunctionComponent<InvitationAcceptedProps> = (props) => {
    const classes = useStyles()
    const orgName = useSelector((state: IStoreState) => state.invitation?.organization.name)
    const tenant = useSelector((state: IStoreState) => state.tenant)
    const { formatMessage: intl } = useIntl()
    const loginURL = process.env.REACT_APP_EXTERNAL_URI

    return (
        <div className={ classes.root }>
            <Grid container direction="row">
                <Grid item xs>
                    <Box>
                        <img src={InvitationAcceptedImg} alt="" width={250} />
                    </Box>
                    <Box>
                        <Typography component="h1" variant="h5">
                            { intl({ id: "invitations.accept.title" }) }
                        </Typography>
                        <Typography component="h2" variant="subtitle1">
                            { intl({ id: "invitations.accept.subtitle" }, { orgName: orgName ? orgName : tenant }) }
                        </Typography>
                        <Button
                            onClick={ () => window.location.assign("" + loginURL)}
                            style={ { marginTop: 10, color: "white", fontWeight: "bold" } }
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Sign In
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}