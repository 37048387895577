import React, { FunctionComponent } from "react"
import { Provider } from "../store/types";
import { MainContainer } from "./MainContainer";

type IdentityProviderChoiceProps = {
    providers?: any
}

export const IdentityProviderChoice: FunctionComponent<IdentityProviderChoiceProps> = (props) => {

    return (
          <MainContainer isGoogleSignInAvailable = {props.providers && 
            props.providers?.split(",").length > 1 && 
            props.providers?.includes(`${Provider.google}`)}>
            {props.children}
          </MainContainer>
    )
}