import React, { FunctionComponent } from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from "@material-ui/core"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"
import InvitationRefusedImg from "../assets/invitation_refused.svg"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    }
}))

type InvitationRefusedProps = {}

export const InvitationRefused: FunctionComponent<InvitationRefusedProps> = (props) => {
    const classes = useStyles()
    const orgName = useSelector((state: IStoreState) => state.invitation?.organization.name)
    const tenant = useSelector((state: IStoreState) => state.tenant)
    const { formatMessage: intl } = useIntl()

    return (
        <div className={ classes.root }>
            <Grid container direction="row">
                <Grid item xs>
                    <Box style={ { marginTop: 10 } }>
                        <img src={ InvitationRefusedImg } alt="invitation-refused-img" width={ 250 }/>
                    </Box>
                    <Box style={ { marginTop: 10 } }>
                        <Typography component="h1" variant="h5">
                            { intl({ id: "invitations.deny.title" }) }
                        </Typography>
                        <Typography component="h2" variant="subtitle1">
                            { intl({ id: "invitations.deny.subtitle" }, { orgName: orgName ? orgName : tenant }) }
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}