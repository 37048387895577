import { LOGOUT, SET_API_FAILURE, SET_API_SUCCESS, SET_INVITATION, SET_LOADING, SET_LOCALE, SET_SERVICE, SET_USER } from "./constants"
import { IInvitation, IService, IUser } from "./types"
import { IAPIError } from "../api/types"

export interface ISetLocale {
    type: typeof SET_LOCALE
    locale: "en" | "it"
}

export interface ISetService {
    type: typeof SET_SERVICE
    service: IService
}

export interface ISetUser {
    type: typeof SET_USER
    user: IUser
}

export interface ISetInvitation {
    type: typeof SET_INVITATION
    invitation: IInvitation
}

export interface ILogout {
    type: typeof LOGOUT
}

export interface ISetLoading {
    type: typeof SET_LOADING
    pending: boolean
}

export function setLocale(locale: "en" | "it"): ISetLocale {
    return {
        type: SET_LOCALE,
        locale
    }
}

export interface IApiRequestFailure {
    type: typeof SET_API_FAILURE
    error: IAPIError
}

export interface IApiRequestSuccess {
    type: typeof SET_API_SUCCESS
}

//define actions
export function setApiRequestPending(pending: boolean): ISetLoading {
    return {
        type: SET_LOADING,
        pending
    }
}

export function setApiRequestFailed(error: any): IApiRequestFailure {
    return {
        type: SET_API_FAILURE,
        error
    }
}

export function setApiRequestSucceed(): IApiRequestSuccess {
    return {
        type: SET_API_SUCCESS,
    }
}

export function setLoading(pending: boolean): ISetLoading {
    return {
        type: SET_LOADING,
        pending
    }
}

export function setService(service: IService): ISetService {
    return {
        type: SET_SERVICE,
        service
    }
}

export function setInvitation(invitation: IInvitation): ISetInvitation {
    return {
        type: SET_INVITATION,
        invitation
    }
}

export function setUser(user: IUser): ISetUser {
    return {
        type: SET_USER,
        user
    }
}

export function logout(): ILogout {
    return {
        type: LOGOUT
    }
}

export type ActionTypes = ISetLoading | IApiRequestFailure | IApiRequestSuccess | ISetUser | ISetInvitation | ILogout | ISetLocale | ISetService
