import React, { FunctionComponent, useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import Link from "@material-ui/core/Link"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Tooltip } from "@material-ui/core"
import { InvitationStep } from "../flows/Invitation"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { IInvitation, IStoreState } from "../store/types"
import { asyncGetInvitation } from "../store/thunks"
import { useLocation, useHistory } from "react-router-dom"
import qs from "qs"
import apiService from "../api"
import { setLoading } from "../store/actions"
import { AZURE_ACTIVE_DIRECTORY } from "../utils/identityProviders"

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: "#fff",
        maxWidth: 450,
        minHeight: 700,
        borderRadius: "8px",
        border: "1px solid #ccc",
        overflow: "hidden"
    },
    buttonLink: {
        fontWeight: "bold",
        color: "#1973e8"
    },

    littleLink: {
        color: "#696969",
        fontWeight: "bold"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        color: "#fff",
        fontWeight: "bold",
        textTransform: "none"
    },
    imageIcon: {
        height: 48,
        width: 48
    },
    iconRoot: {
        height: 48,
        width: 48,
        textAlign: "center",
        marginBottom: theme.spacing(2)
    },
    progress: {
        width: "100%",
        borderRadius: "8px"
    },
    content: {
        overflowY: "auto",
        width: "100%",
        position: "relative"
    },
    fullWidth: {
        width: "100%",
        margin: 0,
        padding: 0
    },
    columnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    bg: {
        position: "absolute",
        zIndex: -1,
        left: 0
    },
    transparent: {
        backgroundColor: "transparent"
    },
    bold: {
        fontWeight: "bold"
    },
    permissions: {
        minWidth: 0,
        padding: theme.spacing(0)
    },
    hidden: {
        visibility: "hidden"
    },
    appDescription: {
        marginLeft: "6px"
    },
    applications: {
        paddingLeft: "0px"
    }
}))

type InvitationStepProps = {
    onStepNavigation: (step: InvitationStep) => void
}

export const AcceptInvitationStep: FunctionComponent<InvitationStepProps> = (props) => {
    const classes = useStyles()
    const { formatMessage: intl } = useIntl()
    const tenant = useSelector((state: IStoreState) => state.tenant)
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const loading = useSelector<IStoreState, boolean>(state => state.loading)
    const invitation = useSelector<IStoreState, IInvitation | undefined>(state => state.invitation)
    const [internalLoading, setInternalLoading] = useState(false)

    useEffect(() => {
        const {
            id
        } = qs.parse(location.search, { ignoreQueryPrefix: true })
        if (id && typeof id == "string" && !invitation) {
            dispatch(asyncGetInvitation(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onSubmit = async () => {
        if (invitation?.alreadyRegistered && invitation.idInvitation) {
            try {
                setInternalLoading(true)
                await apiService.acceptInvitation(invitation.idInvitation, { 
                    userInvitationType: 'NOVAFUTUR_EXISTING_USER'
                })
                history.push("/invite/accepted")
                setInternalLoading(false)
            } catch (e) {

            }
        } else if(invitation?.identityProvider === AZURE_ACTIVE_DIRECTORY) {
            setInternalLoading(true)
            await apiService.acceptInvitation(invitation.idInvitation, {userInvitationType: 'AZURE_ACTIVE_DIRECTORY'})
            history.push("/invite/accepted")
            setInternalLoading(false)
        } else {
            props.onStepNavigation(InvitationStep.personalInfo)
        }
    }

    const onDeny = async () => {
        if (invitation?.idInvitation) {
            try {
                setLoading(true)
                await apiService.denyInvitation(invitation.idInvitation)
                history.push("/invite/refused")
            } catch (e) {
            }
        }
    }

    const getContent = () => {
        if (invitation && invitation.status !== 'DELETED') {
            return (<Box>
                    <div className={ classes.content }>
                        <Box>
                            <Typography component="h2" variant="subtitle1" align="left">
                                { intl({ id: "invStep.invitation.first" }) }, { intl({ id: "invStep.invitation.second" }, { company: tenant, orgName: invitation?.organization.name }) }
                            </Typography>
                        </Box>
                        <Box>
                            <Box mt={ 1 }>
                                <Typography component="h2" variant="subtitle1" align="left">
                                    { intl({ id: "invStep.allow.first" }) }
                                    <Typography className={ classes.bold } component="span"
                                                variant="subtitle1"> { invitation?.organization.name }
                                    </Typography> { intl({ id: "invStep.allow.second" }) }
                                </Typography>
                            </Box>
                            <Box mt={ 1 }>
                                <List dense={ true }>
                                    { invitation?.apps.map(app => {
                                        const labelId = `checkbox-list-label-${ app.idApplication }`
                                        return (
                                            <><ListItem disableGutters={ true } key={ app.idApplication } dense={ true }
                                                        role={ undefined }>
                                                <ListItemText className={ classes.appDescription } id={ labelId }
                                                              primary={ app.name }/>
                                                <ListItemSecondaryAction hidden={ !app.description }>
                                                    <Tooltip title={ app.description || "" }>
                                                        <IconButton edge="end" aria-label="info">
                                                            <InfoOutlinedIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                                <List key={ app.idApplication + ".roles" } style={ { marginLeft: 20 } }
                                                      dense={ true }>
                                                    { invitation.roles.filter((role) => app.idApplication === role.idApplication).map(role => {
                                                        const labelId = `list-label-${ role.idRole }`
                                                        return (
                                                            <ListItem key={ role.idRole } dense={ true }
                                                                      role={ undefined }>
                                                                <ListItemText id={ labelId } primary={ role.name }/>
                                                                <ListItemSecondaryAction hidden={ !role.description }>
                                                                    <Tooltip title={ role.description || "" }>
                                                                        <IconButton edge="end" aria-label="info">
                                                                            <InfoOutlinedIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        )
                                                    }) }
                                                </List>
                                            </>
                                        )
                                    }) }
                                </List>
                            </Box>
                            <Typography component="h2" variant="subtitle2"
                                        align="left">
                                { intl({ id: "invStep.disclaimer" }, { orgName: tenant }) }<Link>{ intl({ id: "invStep.terms" }) }</Link> & <Link>{ intl({ id: "invStep.privacy" }) }</Link>.</Typography>
                        </Box>
                    </div>
                    <Box mt={ 2 }>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={ 4 }>
                                <Box mt={ 1 } textAlign="left">
                                    <Link className={ classes.buttonLink } href="" onClick={ (e: any) => {
                                        e.preventDefault()
                                        onDeny()
                                    } }>
                                        { intl({ id: "steps.refuse" }) }
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item xs={ 4 }>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={ loading || internalLoading }
                                    className={ classes.submit }
                                    onClick={ onSubmit }
                                >
                                    { invitation?.alreadyRegistered ? intl({ id: "steps.join" }) : intl({ id: "steps.next" }) }
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )
        } else {
            return (<Box mt={ 4 }>
                <img alt="Not Found" width="350" src="not_found_search.svg"/>
                <Box mt={ 2 }>
                    <Typography>
                        { intl({ id: "invStep.invitation.error" }) }
                    </Typography>
                </Box>
            </Box>)
        }
    }


    return loading ? (
        <Box mt={ 6 }><Typography>{ intl({ id: "invStep.invitation.loading" }) }</Typography></Box>) : getContent()
}