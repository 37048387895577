import { ActionTypes } from "./actions"
import { LOGOUT, SET_API_FAILURE, SET_API_SUCCESS, SET_INVITATION, SET_LOADING, SET_LOCALE, SET_SERVICE, SET_USER } from "./constants"
import { IStoreState } from "./types"

export const initialState: IStoreState = {
    loading: false,
    locale: "en",
    service: {
        id: "nova_auth",
        name: process.env.REACT_APP_AUTH_SERVICE_NAME || "",
        redirectUri: process.env.REACT_APP_DEFAULT_REDIRECT_URI || "",
        policyUrl: "",
        termsUrl: "",
        idOrganisation: "",
        providers: []
    },
    tenant: process.env.REACT_APP_AUTH_TENANT || ""
}

export function reducer(state = initialState, action: ActionTypes): IStoreState {
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.user }
        case LOGOUT:
            delete state.user
            delete state.error
            return { ...state }
        case SET_LOCALE:
            return {
                ...state,
                locale: action.locale
            }
        case SET_SERVICE:
            delete state.error
            return {
                ...state,
                service: action.service
            }
        case SET_INVITATION:
            return {
                ...state,
                invitation: action.invitation
            }
        case SET_LOADING:
            if (action.pending) delete state.error
            return {
                ...state,
                loading: action.pending
            }
        case SET_API_SUCCESS:
            delete state.error
            return {
                ...state
            }
        case SET_API_FAILURE:
            delete state.data
            return {
                ...state,
                error: action.error
            }
    }
    return state
}