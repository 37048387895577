import {
  Fade,
  Grid,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import NovaIcon from "../assets/nova_icon.svg";
import { Loader } from "../components/Loader";
import { locales } from "../intl";
import { setLocale } from "../store/actions";
import { IStoreState } from "../store/types";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  iconRoot: {
    height: 48,
    width: 48,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  imageIcon: {
        height: 32,
        width: 180
  },
  stepRoot: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    backgroundColor: "#fff",
    minHeight: 450,
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
      maxWidth: 450,
      minHeight: 450,
      height: "105%",
      borderRadius: "8px",
      border: "1px solid #ccc",
      overflow: "hidden",
    },
  },
  legalLink: {
    color: "#696969",
    fontWeight: "bold",
  },
  progress: {
    width: "100%",
    borderRadius: "8px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0),
      imageIcon: {
        height: 32,
        width: 180,
      },
    },
  },
  content: {
    //width: '100%',
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  columnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  transparent: {
    backgroundColor: "transparent",
  },
  bottomBar: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      position: "absolute",
      bottom: 0,
      left: 0,
    },
  },
  translateIcon: {
    fontSize: 14,
  },
  translateItem: {
    color: "#696969",
    cursor: "pointer",
    width: "auto",
    fontWeight: "bold",
  },
}));

type MainContainerProps = {
  animation?: boolean;
  isGoogleSignInAvailable?: any;
};

export const MainContainer: FunctionComponent<MainContainerProps> = (props) => {
  const loading = useSelector((state: IStoreState) => state.loading);
  const locale = useSelector((state: IStoreState) => state.locale);
  const tenant = useSelector((state: IStoreState) => state.tenant);
  const location = useLocation<any>();
  const dispatch = useDispatch();
  const service = useSelector((state: IStoreState) => state.service);

  const classes = useStyles();

  const [anchorLanguageMenuEl, setAnchorLanguageMenuEl] =
    React.useState<null | HTMLElement>(null);
  const isLanguageMenuOpen = Boolean(anchorLanguageMenuEl);
  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLanguageMenuEl(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setAnchorLanguageMenuEl(null);
  };
  const changeLanguage = (locale: "en" | "it") => {
    dispatch(setLocale(locale));
    handleLanguageMenuClose();
  };
  const languageMenuId = "language-menu";
  const renderLanguageMenu = (
    <Menu
      anchorEl={anchorLanguageMenuEl}
      id={languageMenuId}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem onClick={() => changeLanguage("en")}>
        <img alt="Uk flag" width="30" src={require("../assets/uk.svg")} />
        <Typography style={{ marginLeft: 10 }}>English</Typography>
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("it")}>
        <img
          alt="Italian flag"
          width="30"
          src={require("../assets/italy.svg")}
        />
        <Typography style={{ marginLeft: 10 }}>Italiano</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Container
      className={clsx(classes.root, classes.columnCenter)}
      component="main"
    >
      <Paper className={classes.transparent} elevation={0}>
        <CssBaseline />
        <div className={clsx(classes.paper, classes.columnCenter)}>
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? "50ms" : "0ms",
            }}
          >
            <LinearProgress
              variant="indeterminate"
              className={classes.progress}
            />
          </Fade>
          <div className={clsx(classes.content, classes.columnCenter)}>
            <div className={classes.stepRoot}>
              <Grid
                container
                direction="row"
                style={{
                  width: "90vw",
                  maxWidth: "370px",
                  margin: "auto",
                  justifyContent: "left",
                }}
              >
                <Grid item xs style={{ textAlign: "left" }}>
                  <Icon classes={{ root: classes.iconRoot }}>
                    <img
                      className={classes.imageIcon}
                      alt={tenant + " Logo"}
                      src={service.imgUrl || NovaIcon}
                    />
                  </Icon>
                  
                  {props.animation ? (
                    <TransitionGroup
                      childFactory={(child: any) =>
                        React.cloneElement(child, {
                          classNames: location.state?.transition,
                        })
                      }
                    >
                      <CSSTransition
                        key={location.key}
                        timeout={500}
                        unmountOnExit={true}
                      >
                        {props.children}
                      </CSSTransition>
                    </TransitionGroup>
                  ) : (
                    props.children
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Grid
          container
          justify="space-between"
          className={clsx(classes.transparent, classes.bottomBar)}
        >
          <Grid
            container
            item
            justify={"flex-start"}
            className={classes.translateItem}
            onClick={handleLanguageMenuOpen}
          >
            <Box mt={1} mr={1} textAlign="left">
              {locales[locale]}
            </Box>
            <Box mt={1} textAlign="left">
              <ExpandMoreIcon fontSize="small" />
            </Box>
          </Grid>
          {/* <Grid item container xs={ 6 } justify={ "flex-end" }>
                        <Grid item>
                            <Box mt={ 1 } mr={ 2 } textAlign="left">
                                <Link className={ classes.legalLink } href={ service.termsUrl }
                                      onClick={ preventDefault }>
                                    { intl({ id: "legal.help" }) }
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box mt={ 1 } ml={ 2 } textAlign="left">
                                <Link className={ classes.legalLink } href={ service.policyUrl }
                                      onClick={ preventDefault }>
                                    { intl({ id: "legal.policy" }) }
                                </Link>
                            </Box>
                        </Grid>
                    </Grid> */}
        </Grid>
      </Paper>
      {renderLanguageMenu}
    </Container>
  );
};
