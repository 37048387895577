import React, { FunctionComponent } from "react"
import { SignInStep } from "./SignIn"
import { SignUpStep } from "./SignUp"
import { AcceptInvitationStep } from "../steps/AcceptInvitationStep"
import { ChoosePasswordStep } from "../steps/ChoosePasswordStep"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { NewPasswordStep } from "./NewPassword"
import { PersonalInfoStep } from "../steps/PersonalInfoStep"
import { InvitationAccepted } from "../steps/InvitationAccepted"
import { InvitationRefused } from "../steps/InvitationRefused"
import { useSelector } from "react-redux"
import { IStoreState } from "../store/types"

export enum InvitationStep {
    invite = "invite",
    personalInfo = "personal-info",
    password = "password"
}

type FinalizeBoardingFlowProps = {}

export const FinalizeBoardingFlow: FunctionComponent<FinalizeBoardingFlowProps> = (props) => {
    const location = useLocation()
    const history = useHistory()
    const invitation = useSelector<IStoreState, string | undefined>( state => state.invitation?.idInvitation)

    function handleStepNavigation(nextStep: NewPasswordStep | SignInStep | SignUpStep | InvitationStep, back = false) {
        const transition = back ? "slideback" : "slide"
        switch (nextStep) {
            case InvitationStep.invite:
                history.push(`/invite?id=${ invitation }`, { transition })
                break
            case InvitationStep.personalInfo:
                history.push("/invite/info", { transition })
                break
            case InvitationStep.password:
                history.push("/invite/pwd", { transition })
                break
            default:
                history.push("/invite/accept", { transition })
        }
    }

    return (
        <Switch location={ location }>
            <Route exact path="/invite">
                <AcceptInvitationStep onStepNavigation={ handleStepNavigation }/>
            </Route>
            <Route exact path="/invite/info">
                <PersonalInfoStep type="boarding" onStepNavigation={ handleStepNavigation }/>
            </Route>
            <Route exact path="/invite/pwd">
                <ChoosePasswordStep type="boarding" onStepNavigation={ handleStepNavigation }/>
            </Route>
            <Route exact path="/invite/accepted">
                <InvitationAccepted />
            </Route>
            <Route exact path="/invite/refused">
                <InvitationRefused />
            </Route>
        </Switch>
    )
}